/**
 * Set up a decent box model on the root element
 * 1. font-size 1rem = 10px on default browser settings
 */
html {
  font-size: 62.5%; /* 1 */
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

@media print {
  html,
  body {
    height: auto;
    overflow: visible;
  }
}

/**
 * Basic typography style for copy text
 */
body {
  font-family: 'Lato', sans-serif;
  line-height: 1.4;
  color: #000;
}

#root,
#root > div {
  height: 100%;
}
